import { createRootRoute } from '@tanstack/react-router';

import App from '@/App';

export const Route = createRootRoute({
  component: Root,
});

export function Root() {
  return <App />;
}
