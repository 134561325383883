import { message } from 'antd';

import { ROUTE_CONSTANTS } from '@/routes';

export function showMessage(type: 'success' | 'error', content: string) {
  void message.open({ type, content, duration: 5 });
}

export function logger(message: string, level = 'info') {
  switch (level) {
    case 'info':
      console.info(message);
      break;
    case 'warn':
      console.warn(message);
      break;
    case 'error':
      console.error(message);
      break;
    default:
      console.log(message);
      break;
  }
}
export function checkRoutePath(pathname: string) {
  const paths = [ROUTE_CONSTANTS.APPS_PATH];

  return paths.find((path) => pathname.includes(path)) || '';
}

export function getImageUrl(imagePath: string) {
  const baseUrl = process.env.NODE_ENV === 'development' ? '/api' : '';
  return `${baseUrl}${imagePath}`;
}
