import { createFileRoute } from '@tanstack/react-router';
import { Col, Divider, Typography } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import {
  useDownloadArtifacts,
  useGetApplicationByName,
  useGetReleaseChannelByName,
  useGetReleaseChannels,
} from '@/api';
import { AppFileDownloadList, AppImageGallery, AppInfoHeader } from '@/components/app';
import { ReleaseChannelInfoFileDTO } from '@/proxy';

export const Route = createFileRoute('/apps/$name')({
  component: AppDetailPage,
});

function AppDetailPage() {
  const { name } = Route.useParams();
  const { data: application } = useGetApplicationByName({ app: name });

  const downloadArtifact = useDownloadArtifacts();

  const { data: channels, isLoading: channelIsLoading } = useGetReleaseChannels({ appName: name });
  const [selectedChannel, setSelectedChannel] = useState<string>('');

  const channel = useGetReleaseChannelByName({
    appName: name,
    channel: selectedChannel,
  });

  useEffect(() => {
    if (selectedChannel === '' && channels && channels.length > 0) {
      setSelectedChannel(channels[0]);
    }
    void channel.refetch();
  }, [channels?.length, channel, selectedChannel]);

  function onReleaseChannelChange(channel: string) {
    setSelectedChannel(channel);
  }

  function onDownloadClick(file: ReleaseChannelInfoFileDTO) {
    downloadArtifact.mutate({
      repoSlug: name,
      filename: file.url,
    });
  }

  return application ? (
    <Col>
      <AppInfoHeader
        displayName={application.displayName}
        logo={application.logo}
        shortDescription={application.shortDescription}
        channels={channels ?? []}
        selectedChannel={selectedChannel}
        onReleaseChannelChange={onReleaseChannelChange}
      />
      <Divider />
      <Typography.Title level={4}>Dateien</Typography.Title>
      <AppFileDownloadList
        isLoading={channelIsLoading}
        version={channel.data?.version ?? ''}
        releaseDate={dayjs(channel.data?.releaseDate)}
        files={channel.data?.files ?? []}
        onDownloadClick={onDownloadClick}
      />
      <Divider />
      <Typography.Title level={4}>Vorschau</Typography.Title>
      <AppImageGallery imageUrls={application.images} />
      <Divider />
      <Col span={24}>
        <Typography.Title level={4}>Beschreibung</Typography.Title>
        <Typography dangerouslySetInnerHTML={{ __html: application.description }}></Typography>
      </Col>
    </Col>
  ) : (
    <Typography.Text>Loading...</Typography.Text>
  );
}
