import { LeftOutlined, RightOutlined, ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { Image, List, Row, Space } from 'antd';

import { getImageUrl } from '@/shared/utils';

export type AppImageGalleryProps = {
  imageUrls: string[];
};

export function AppImageGallery({ imageUrls }: AppImageGalleryProps) {
  return (
    <Row justify={'center'}>
      <Image.PreviewGroup
        preview={{
          toolbarRender: (
            _,
            { transform: { scale }, actions: { onActive, onZoomOut, onZoomIn } }
          ) => (
            <Space size={12} className='toolbar-wrapper'>
              <LeftOutlined onClick={() => onActive?.(-1)} />
              <RightOutlined onClick={() => onActive?.(1)} />
              <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
              <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
            </Space>
          ),
        }}
      >
        <List
          itemLayout='horizontal'
          style={{
            display: 'flex',
            whiteSpace: 'nowrap',
            overflowX: 'auto',
          }}
          dataSource={imageUrls}
          renderItem={(src) => (
            <Image
              src={getImageUrl(src)}
              width={500}
              preview={true}
              style={{ flex: '0 0 auto', padding: '8px' }}
            />
          )}
        />
      </Image.PreviewGroup>
    </Row>
  );
}
