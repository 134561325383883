import './App.css';

import { MainLayout } from './components/layout';
import { AppProvider } from './providers';

function App() {
  return (
    <AppProvider>
      <MainLayout />
    </AppProvider>
  );
}

export default App;
