import { Outlet } from '@tanstack/react-router';
import { Layout } from 'antd';
import React from 'react';

const style: React.CSSProperties = {
  padding: '24px',
  overflowY: 'auto',
};

export const MainContent = () => {
  return (
    <Layout.Content style={style}>
      <Outlet />
    </Layout.Content>
  );
};
