import { useMutation } from '@tanstack/react-query';

import { getMailallianceAppDistributorAPI } from '@/proxy';

export type DownloadArtifactParams = {
  repoSlug: string;
  filename: string;
};

async function downloadArtifact(params: DownloadArtifactParams) {
  return getMailallianceAppDistributorAPI().applicationsControllerDownloadReleaseByName(
    params.repoSlug,
    params.filename
  );
}

export function useDownloadArtifacts() {
  return useMutation({
    mutationFn: downloadArtifact,
    onSuccess: (data) => {
      window.open(data.url);
    },
  });
}
