/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * mailalliance App Distributor API
 * OpenAPI spec version: 1.0
 */
import { customAxios } from '../lib/axios';

import type { ApplicationDTO, ReleaseChannelInfoDTO, ReleaseDownloadDTO } from './dtos';

export const getMailallianceAppDistributorAPI = () => {
  /**
   * @summary Get all applications
   */
  const applicationsControllerGetApplications = () => {
    return customAxios<ApplicationDTO[]>({ url: `/applications`, method: 'GET' });
  };

  /**
   * @summary Get all applications
   */
  const applicationsControllerGetApplicationByName = (app: string) => {
    return customAxios<ApplicationDTO>({ url: `/applications/${app}`, method: 'GET' });
  };

  /**
   * @summary Get all release channels for a specific application
   */
  const applicationsControllerGetReleaseChannelsByApp = (app: string) => {
    return customAxios<string[]>({ url: `/applications/${app}/channels`, method: 'GET' });
  };

  /**
   * @summary Get the latest application release for a channel
   */
  const applicationsControllerGetAppReleaseChannelInfo = (app: string, channel: string) => {
    return customAxios<ReleaseChannelInfoDTO>({
      url: `/applications/${app}/channels/${channel}`,
      method: 'GET',
    });
  };

  /**
   * @summary Download a specific application release
   */
  const applicationsControllerDownloadReleaseByName = (app: string, filename: string) => {
    return customAxios<ReleaseDownloadDTO>({
      url: `/applications/${app}/download/${filename}`,
      method: 'GET',
    });
  };

  return {
    applicationsControllerGetApplications,
    applicationsControllerGetApplicationByName,
    applicationsControllerGetReleaseChannelsByApp,
    applicationsControllerGetAppReleaseChannelInfo,
    applicationsControllerDownloadReleaseByName,
  };
};
export type ApplicationsControllerGetApplicationsResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof getMailallianceAppDistributorAPI>['applicationsControllerGetApplications']
    >
  >
>;
export type ApplicationsControllerGetApplicationByNameResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<
        typeof getMailallianceAppDistributorAPI
      >['applicationsControllerGetApplicationByName']
    >
  >
>;
export type ApplicationsControllerGetReleaseChannelsByAppResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<
        typeof getMailallianceAppDistributorAPI
      >['applicationsControllerGetReleaseChannelsByApp']
    >
  >
>;
export type ApplicationsControllerGetAppReleaseChannelInfoResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<
        typeof getMailallianceAppDistributorAPI
      >['applicationsControllerGetAppReleaseChannelInfo']
    >
  >
>;
export type ApplicationsControllerDownloadReleaseByNameResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<
        typeof getMailallianceAppDistributorAPI
      >['applicationsControllerDownloadReleaseByName']
    >
  >
>;
