import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/lib';
import { getMailallianceAppDistributorAPI } from '@/proxy';

export type GetApplicationByNameParams = {
  app: string;
};

function getApplicationByName(params: GetApplicationByNameParams) {
  return getMailallianceAppDistributorAPI().applicationsControllerGetApplicationByName(params.app);
}

export function useGetApplicationByName(params: GetApplicationByNameParams) {
  return useQuery({
    queryKey: queryKeys.applications.detail(params.app),
    queryFn: () => getApplicationByName(params),
  });
}
