import { Button, Col, Divider, List, Row, Space, Typography } from 'antd';
import { Dayjs } from 'dayjs';
import { CSSProperties } from 'react';

import type { ReleaseChannelInfoFileDTO } from '@/proxy';

export type AppFileDownloadListProps = {
  version: string;
  releaseDate: Dayjs;
  files: ReleaseChannelInfoFileDTO[];
  isLoading: boolean;
  onDownloadClick: (file: ReleaseChannelInfoFileDTO) => void;
};

export const AppFileDownloadList = ({
  files,
  releaseDate,
  version,
  isLoading,
  onDownloadClick,
}: AppFileDownloadListProps) => {
  const metaTitleStyle: CSSProperties = {
    fontSize: '0.7rem',
    textTransform: 'uppercase',
  };

  return (
    <List
      loading={isLoading}
      dataSource={files}
      renderItem={(file, index) => (
        <>
          <Row style={{ width: '100%' }} align={'middle'}>
            <Col span={5} style={{ textAlign: 'center' }}>
              <Space direction={'vertical'} align={'center'}>
                <Typography.Text type={'secondary'} style={metaTitleStyle}>
                  Name
                </Typography.Text>
                <Typography.Text>{file.url}</Typography.Text>
              </Space>
            </Col>
            <Col span={5} style={{ textAlign: 'center' }}>
              <Space direction={'vertical'} align={'center'}>
                <Typography.Text type={'secondary'} style={metaTitleStyle}>
                  Version
                </Typography.Text>
                <Typography.Text>{version}</Typography.Text>
              </Space>
            </Col>
            <Col span={5} style={{ textAlign: 'center' }}>
              <Space direction={'vertical'} align={'center'}>
                <Typography.Text type={'secondary'} style={metaTitleStyle}>
                  Datum
                </Typography.Text>
                <Typography.Text>{releaseDate.format('DD.MM.YYYY')}</Typography.Text>
              </Space>
            </Col>
            <Col span={5} style={{ textAlign: 'center' }}>
              <Space direction={'vertical'} align={'center'}>
                <Typography.Text type={'secondary'} style={metaTitleStyle}>
                  Größe
                </Typography.Text>
                <Typography.Text>{(file.size / 1024 / 1000).toFixed(2)} MB</Typography.Text>
              </Space>
            </Col>
            <Col span={4} style={{ textAlign: 'center' }}>
              <Button type={'primary'} onClick={() => onDownloadClick(file)}>
                Herunterladen
              </Button>
            </Col>
          </Row>
          {index < files.length - 1 && <Divider />}
        </>
      )}
    />
  );
};
