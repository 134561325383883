import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/lib';
import { getMailallianceAppDistributorAPI } from '@/proxy';

export type GetDownloadArtifactsParams = {
  appName: string;
  channel: string;
};

function getReleaseChannelByName(params: GetDownloadArtifactsParams) {
  return getMailallianceAppDistributorAPI().applicationsControllerGetAppReleaseChannelInfo(
    params.appName,
    params.channel
  );
}

export function useGetReleaseChannelByName(params: GetDownloadArtifactsParams) {
  return useQuery({
    queryKey: queryKeys.releases.detail(params.appName, params.channel),
    queryFn: () => getReleaseChannelByName(params),
    enabled: !!params.appName && params.appName.length > 0 && params.channel.length > 0,
  });
}
