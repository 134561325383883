import { ProductOutlined } from '@ant-design/icons';
import { Link, useLocation } from '@tanstack/react-router';
import { Layout, Menu } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import { MainContent } from './MainContent';

import { MainHeader } from '@/components/layout';
import { MainFooter } from '@/components/layout/MainFooter';
import { ROUTE_CONSTANTS } from '@/routes';
import { checkRoutePath, getImageUrl } from '@/shared/utils';

export function MainLayout() {
  const [currentRoute, setCurrentRoute] = useState(ROUTE_CONSTANTS.APPS_PATH);
  const location = useLocation();

  useEffect(() => {
    console.log(location.pathname);
    setCurrentRoute(checkRoutePath(location.pathname));
  }, [location.pathname]);

  const menuItems = useMemo(
    () => [
      {
        key: ROUTE_CONSTANTS.APPS_PATH,
        icon: <ProductOutlined />,
        label: <Link to={ROUTE_CONSTANTS.APPS_PATH}>Apps</Link>,
      },
    ],
    []
  );

  return (
    <Layout style={{ height: '100vh', flexDirection: 'row' }}>
      <Layout.Sider theme={'light'} collapsed={false}>
        <div style={{ height: 32, margin: 16 }}>
          <Link to='/' replace>
            <img src={getImageUrl('/images/mailalliance-logo.svg')} alt='mailalliance Logo' />
          </Link>
        </div>
        <Menu mode='inline' selectedKeys={[currentRoute]} items={menuItems} />
      </Layout.Sider>
      <Layout>
        <MainHeader />
        <MainContent />
        <MainFooter />
      </Layout>
    </Layout>
  );
}
