import Axios, { AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from 'axios';

import { showMessage } from '@/shared/utils';

const baseUrl = process.env.NODE_ENV === 'development' ? '/api' : '';

export const AXIOS_INSTANCE = Axios.create({
  baseURL: baseUrl,
  timeout: 10000,
});

function requestInterceptor(config: InternalAxiosRequestConfig) {
  config.headers.Accept = 'application/json';
  return config;
}

function onFulfilled(
  value: AxiosResponse<any, any>
): AxiosResponse<any, any> | Promise<AxiosResponse<any, any>> {
  return value;
}

async function onRejected(error: Error): Promise<any> {
  showMessage('error', error.message);
  return Promise.reject(error);
}

AXIOS_INSTANCE.interceptors.request.use(requestInterceptor);
AXIOS_INSTANCE.interceptors.response.use(onFulfilled, onRejected);

export const customAxios = <T>(config: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({
    ...config,
    cancelToken: source.token,
  }).then(({ data }) => data);

  // @ts-expect-error - cancel is not part of the promise
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};
