import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/lib';
import { getMailallianceAppDistributorAPI } from '@/proxy';

export type GetReleaseChannelsParams = {
  appName: string;
};

function getReleaseChannels(params: GetReleaseChannelsParams) {
  return getMailallianceAppDistributorAPI().applicationsControllerGetReleaseChannelsByApp(
    params.appName
  );
}

export function useGetReleaseChannels(params: GetReleaseChannelsParams) {
  return useQuery({
    queryKey: queryKeys.channels.detail(params.appName),
    queryFn: () => getReleaseChannels(params),
    select: (data) => {
      return data;
    },
    enabled: !!params.appName && params.appName.length > 0,
  });
}
