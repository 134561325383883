import { createFileRoute, Link } from '@tanstack/react-router';
import { Col, Divider, Flex, Row, Space, Typography } from 'antd';

import { useGetApplications } from '@/api/useGetApplications';
import { getImageUrl } from '@/shared/utils';

export const Route = createFileRoute('/apps/')({
  component: AppsPage,
});

export function AppsPage() {
  const { data } = useGetApplications();

  return (
    <Col>
      <Typography.Title level={2}>Apps</Typography.Title>
      <Typography.Text>Hier finden Sie eine Übersicht aller verfügbaren Apps.</Typography.Text>
      <Divider />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {data?.map((item) => (
          <Col xs={24} md={24} lg={12} xl={8} xxl={4} style={{ padding: 8 }}>
            <Flex key={item.name} align={'center'} gap={16}>
              <img src={getImageUrl(item.logo)} style={{ width: 75 }} />
              <Space
                direction={'vertical'}
                style={{ justifyContent: 'start', width: '100%' }}
                size={0}
              >
                <Typography.Text strong>{item.displayName}</Typography.Text>
                <Typography.Paragraph type={'secondary'} ellipsis={{ rows: 2 }}>
                  {item.shortDescription}
                </Typography.Paragraph>
              </Space>
              <Link to={`/apps/${item.name}`} style={{ alignSelf: 'center' }}>
                Anzeigen
              </Link>
            </Flex>
          </Col>
        ))}
      </Row>
    </Col>
  );
}
