import { DefaultOptions, MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';

import { showMessage } from '@/shared/utils';

const queryConfig: DefaultOptions = {
  queries: {
    throwOnError: true,
    refetchOnWindowFocus: 'always',
    retry: false,
  },
};

export const defaultStaleTime = 1000 * 60 * 5;

export const queryClient = new QueryClient({
  defaultOptions: queryConfig,
  queryCache: new QueryCache({
    onError: (error) => {
      showMessage('error', error.message);
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      showMessage('error', error.message);
    },
  }),
});

export const queryKeys = {
  applications: {
    all: ['applications'] as const,
    lists: () => [...queryKeys.applications.all, 'list'] as const,
    details: () => [...queryKeys.applications.all] as const,
    detail: (name: string) => [...queryKeys.applications.details(), name] as const,
  },
  releases: {
    all: ['releases'] as const,
    lists: () => [...queryKeys.releases.all, 'list'] as const,
    details: () => [...queryKeys.releases.all] as const,
    detail: (name: string, channel: string) =>
      [...queryKeys.releases.details(), name, channel] as const,
  },
  channels: {
    all: ['channels'] as const,
    lists: () => [...queryKeys.channels.all, 'list'] as const,
    details: () => [...queryKeys.channels.all] as const,
    detail: (name: string) => [...queryKeys.channels.details(), name] as const,
  },
};
