import { useQuery } from '@tanstack/react-query';

import { queryKeys } from '@/lib';
import { getMailallianceAppDistributorAPI } from '@/proxy';

function getApplications() {
  return getMailallianceAppDistributorAPI().applicationsControllerGetApplications();
}

export function useGetApplications() {
  return useQuery({
    queryKey: queryKeys.applications.lists(),
    queryFn: () => getApplications(),
  });
}
