import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import { App, ConfigProvider } from 'antd';
import localeDe from 'antd/locale/de_DE';
import dayjs from 'dayjs';
import { PropsWithChildren, Suspense, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import ErrorFallback from '../components/error/ErrorFallback';

import { queryClient } from '@/lib';
import { themeConfig } from '@/styles/themeConfig';

export function AppProvider({ children }: PropsWithChildren) {
  const [locale, setLocale] = useState(localeDe);
  const { i18n } = useTranslation();

  function onErrorReset() {
    // navigate('./', { replace: true });
  }

  useEffect(() => {
    const language = i18n.language;
    switch (language) {
      case 'de-DE':
        setLocale(localeDe);
        dayjs.locale('de');
        break;
      default:
        setLocale(localeDe);
        dayjs.locale('de');
        break;
    }
  }, [i18n.language]);

  return (
    <ConfigProvider locale={locale} form={{ requiredMark: 'optional' }} theme={themeConfig}>
      <ErrorBoundary FallbackComponent={ErrorFallback} onReset={onErrorReset}>
        <App>
          <Suspense>
            <QueryClientProvider client={queryClient}>
              {children}
              {import.meta.env.s && (
                <>
                  <ReactQueryDevtools position='bottom' initialIsOpen={false} />
                  <TanStackRouterDevtools />
                </>
              )}
            </QueryClientProvider>
          </Suspense>
        </App>
      </ErrorBoundary>
    </ConfigProvider>
  );
}
