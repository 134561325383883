import { Button, Result } from 'antd';
import type { FallbackProps } from 'react-error-boundary';

export function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  switch (error.code) {
    case 403:
    case 401:
      return (
        <Result
          status='403'
          title='403'
          subTitle={'Unauthorized'}
          extra={
            <Button type='primary' onClick={resetErrorBoundary}>
              Home
            </Button>
          }
        />
      );
    case 404:
      return (
        <Result
          status='404'
          title='404'
          subTitle={'Not Found'}
          extra={
            <Button type='primary' onClick={resetErrorBoundary}>
              Home
            </Button>
          }
        />
      );
    default:
      return (
        <Result
          status='warning'
          title={error.code}
          extra={
            <Button type='primary' onClick={resetErrorBoundary}>
              Home
            </Button>
          }
        />
      );
  }
}

export default ErrorFallback;
